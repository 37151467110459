import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import TextHeader from './TextHeader'
import Projekt from './Projekt'
import Matrix from "../images/matrix.svg"

const ProjektList = ({ data }) => {
  return (
    <div className="">

      <Matrix className="h-full w-full border-b p-5 fill-red-600 " />

      <TextHeader title="Projekt" subtitle="Några projekt jag byggt på fritiden." className="p-5 border-b" />
      <div className='grid'>
        <Projekt title="Korta Nyheter" details={["2025", "next.js", "gemini"]} text="AI sammanfattning av nyheter från SVT." link="https://korta-nyheter.blixthalka.com" fluid={data.kortanyheter.childImageSharp.fluid} />
        <Projekt title="Running Pace Calculator" details={["2024", "next.js"]} text="Beräkning hur snabbt du springer en distans givet en hastighet." link="https://pace.blixthalka.com" fluid={data.pace.childImageSharp.fluid} />
        <Projekt title="Löplogg" details={["2024", "next.js", "postgres"]} text="Logg hur min löpträning går." link="https://run.blixthalka.com" fluid={data.run.childImageSharp.fluid} />
        <Projekt title="Arbitrage" details={["2024", "next.js"]} text="Sida för att hitta skillnader mellan tex A aktien och B aktien i samma bolag." link="https://arbitrage.blixthalka.com" fluid={data.arbitrage.childImageSharp.fluid} />
        <Projekt title="Swedish Retail Index" details={["2023", "Erlang", "React"]} text="Ett aktie-index som följer de 50 mest ägda aktierna hos småsparare i Sverige." link="https://srindex.blixthalka.com" fluid={data.srindex.childImageSharp.fluid} />
        <Projekt title="divdash" details={["2022", "React"]} text="En dashboard för utdelningar, läser transaktionsfiler från banken." link="https://divdash.blixthalka.com/" fluid={data.divdash.childImageSharp.fluid} />
        <Projekt title="fx" details={["2024", "next.js"]} text="Sida som visar hur olika valutor gått mot dollarn sen år 2000." link="https://fx.blixthalka.com/" fluid={data.fx.childImageSharp.fluid} />
        <Projekt title="Topography" details={["2022", "React", "d3.js"]} text="Genererar en topografisk SVG-bild, som man kan använda till webbdesign." link="https://topography.blixthalka.com/" fluid={data.topo.childImageSharp.fluid} />
        <Projekt title="Bookmarks" details={["2023", "Supabase", "React"]} text="Minimalistiskt sätt att spara bokmärken." link="https://bookmarks.blixthalka.com" fluid={data.bookmarks.childImageSharp.fluid} />
        <Projekt title="mapx" details={["2021", "Java", "React"]} text="Klustrad heatmap för tränings-aktiviteter. Syncar data via Strava OAuth." link="https://mapx.blixthalka.com" fluid={data.mapx.childImageSharp.fluid} />
      </div>
    </div>
  )
}


const ProjektListWrapper = (props) => {
  return (
    <StaticQuery
      query={graphql`
            query {
              arbitrage: file(relativePath: { eq: "projekt/arbitrage.png" }) {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              kortanyheter: file(relativePath: { eq: "projekt/korta-nyheter.png" }) {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              pace: file(relativePath: { eq: "projekt/pace.png" }) {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              run: file(relativePath: { eq: "projekt/run.png" }) {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
                srindex: file(relativePath: { eq: "projekt/srindex.png" }) {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  topo: file(relativePath: { eq: "projekt/topo.png" }) {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  fx: file(relativePath: { eq: "projekt/fx.png" }) {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  divdash: file(relativePath: { eq: "projekt/divdash.png" }) {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  mapx: file(relativePath: { eq: "projekt/mapx.png" }) {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  bookmarks: file(relativePath: { eq: "projekt/bookmarks.png" }) {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
            }
        `}
      render={data => <ProjektList data={data} {...props} />}
    />
  )
}

export default ProjektListWrapper
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline'
import Img from 'gatsby-image'
import './post.scss'
import PostHeader from '../components/PostHeader'
import Matrix from "../images/matrix.svg"



export default function Template({ data }) {
  const mdx = data.mdx;
  return (
    <div>
      <Layout className="">
        <SEO title={mdx.frontmatter.title} description={mdx.subtitle} />

        <div className="">
          {mdx.frontmatter.image && <Img fluid={mdx.frontmatter.image.childImageSharp.fluid} className=" border-b" />}
          {!mdx.frontmatter.image && <Matrix className="h-full w-full border-b p-5 fill-orange-500 " />}
          <div className="flex justify-between items-center p-5 border-b">
            <div>
              <h1 className="font-medium text-lg text-bloghalka-main ">{mdx.frontmatter.title}</h1>
              <PostHeader timeToRead={mdx.timeToRead} date={mdx.frontmatter.date} sponsored={false} className="" />
            </div>
          </div>


          {mdx.frontmatter.subtitle && (
            <div className="p-5 border-b">
              <p className="italic">{mdx.frontmatter.subtitle}</p>
            </div>)}


          <div className="inner-blogg ">
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </div>
          <div className=" border-b grid grid-cols-2">
            <div className="p-5 border-r text-gray-400">
              <span className="block">Over and out,</span>
              <span className="">Emil</span>
            </div>
            <Link className="hover:bg-gray-100 flex  space-x-2 items-center justify-center group" to="/blogg">
              <ArrowUturnLeftIcon className=" stroke-gray-400   h-5 w-5 group-hover:stroke-gray-900" />
            </Link>
          </div>
        </div>
      </Layout>
    </div >
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      timeToRead
      excerpt
      frontmatter {
        date(formatString: "MMM YYYY")
        path
        subtitle
        title
        image {
          childImageSharp {
            fluid(quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
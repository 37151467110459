import { graphql } from 'gatsby'
import React from 'react'
import BloggListSection from '../components/bloglist-section'
import Layout from '../components/Layout'
import SEO from '../components/seo'

const Index = ({ data }) => {
    return (
        <Layout>
            <SEO title="Blogg" />
            <BloggListSection data={data} className="" />
        </Layout>
    );
}

export const pageQuery = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMM YYYY")
            subtitle
            path
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`
export default Index